<!--
 * @Author: faf
 * @desc: 订单实缴率对比分析
 * @Date: 2022-07-07 11:21:02
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <div class="content1-wrapper">
    <div :id="type" class="chart"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    type: {
      default: "c2"
    },
    chartParam: {
      default: {}
    }
  },
  watch: {
    chartParam: {
      handler(curVal, oldVal) {
        this.drawGraph();
      },
      deep: true
    }
  },
  data() {
    return {};
  },
  methods: {
    drawGraph() {
      let option = {
        title: {
          // text: this.type == 'c2' ? '' : '单位：个',
          text: this.chartParam.unit,
          color: "#9AA4B3",
          left: "26px",
          textStyle: {
            fontSize: 12,
            fontWeight: "400",
            color: "#9AA4B3"
          }
        },
        grid: {
          left: "30px",
          right: "26px",
          bottom: "10px",
          top: "37px",
          containLabel: true
        },
        backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: function (val) {
            let tip = "";
            val.forEach((params) => {
              let val = '';
              if (params.value > 0) {
                val = params.value;
              } else {
                val = '0.00';
              }
              tip += `${params.marker} ${params.axisValue}: ${val}%<br/>`;
            });
            return tip;
          }
        },
        legend: {
          itemWidth: 16,
          itemHeight: 5,
          right: "16px",
          top: "1%",
          textStyle: {
            color: "#9AA4B3"
          }
        },
        xAxis: {
          data: this.chartParam.xData,
          splitLine: { show: false },
          axisTick: { show: false },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#9AA4B3"
            }
          },
          axisLabel: {
            margin: 16
          }
        },
        yAxis: {
          type: "value",
          // splitNumber: 5,
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#9AA4B3"
            }
          },
          // interval: 20,
          axisLabel: {
            formatter: "{value} %"
          },
          min: 0,
          max: 100
        },
        series: [
          {
            type: "bar",
            barWidth: 10,
            left: "10px",
            itemStyle: {
              borderRadius: 2,
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 1, color: "#527BFF" }
              ])
            },
            data: this.chartParam.barData.map((item, ind) => {
              return {
                value: (item * 100).toFixed(2)
              }
            })
          }
        ]
      };
      let chart;
      chart = this.$echarts.init(document.getElementById(this.type));
      chart.setOption(option);
      window.addEventListener("resize", () => {
        chart && chart.resize();
      });
    }
  },
  mounted() {
    this.drawGraph();
  }
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content1-wrapper {
  width: 100%;
  height: 266px;
}

.chart {
  width: 100%;
  height: 266px;
}
</style>