<!--
 * @Author: faf
 * @desc: 客单价/平均泊位收入
 * @Date: 2022-07-07 11:21:02
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <div class="content-wrapper">
    <div :id="type" class="chart"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    type: {
      default: "c2"
    },
    chartParam: {
      default: {}
    }
  },
  watch: {
    chartParam: {
      handler(curVal, oldVal) {
        this.drawGraph();
      },
      deep: true
    }
  },
  data() {
    return {};
  },
  methods: {
    drawGraph() {
      //   let category = ["区域1", "区域2", "区域3", "区域4", "区域5", "区域6"];
      //   // let category = [];
      //   //   let dottedBase = +new Date();
      //   let lineData = [];
      //   let barData = [];
      //   for (let i = 0; i < 6; i++) {
      //     // let date = new Date((dottedBase += 3600 * 24 * 1000));
      //     // category.push(
      //     //   [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-')
      //     // );
      //     let b = Math.random() * 100;
      //     let d = Math.random() * 200;
      //     barData.push(b);
      //     lineData.push(d + b);
      //   }
      let option = {
        title: {
          left: "26px",
          text: this.chartParam.unit,
          textStyle: {
            fontSize: 12,
            fontWeight: "400",
            color: "#9AA4B3"
          }
        },
        grid: {
          left: "30px",
          right: "60px",
          bottom: "18px",
          top: "37px",
          containLabel: true
        },
        xAxis: {
          type: "value",
          min: 0,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed"
            }
          },
          axisTick: { show: false },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#9AA4B3"
            }
          },
          axisLabel: {
            margin: 20
          }
        },
        yAxis: {
          type: "category",
          inverse: true,
          boundaryGap: false,
          axisLabel: {
            color: function (v, i) {
              return i == 0 ? "#AE7CE6" : "#527BFF";
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed"
            }
          },
          axisLine: {
            show: false
          },
          data: this.chartParam.yData
        },
        series: [
          {
            barWidth: 14,
            itemStyle: {
              borderRadius: 2
            },
            data: this.chartParam.xData.map((item, ind) => {
              // 循环
              return {
                value: parseFloat(item),
                itemStyle: {
                  color: ind == 0 ? "#AE7CE6" : "#527BFF"
                },
                label: {
                  show: true, //开启显示
                  position: "right",
                  formatter: (val) => {
                    return val.value > 0 ? (val.value).toFixed(2) + '(元)' : '0(元)'
                  },
                  textStyle: {
                    //数值样式
                    color: ind == 0 ? "#AE7CE6" : "#527BFF", //数值颜色
                    fontSize: "14px"
                  }
                }
              };
            }),
            type: "bar"
          }
        ]
      };
      let chart;
      chart = this.$echarts.init(document.getElementById(this.type));
      chart.setOption(option);
      window.addEventListener("resize", () => {
        option && chart.resize();
      });
    }
  },
  mounted() {
    // this.drawGraph();
  }
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content-wrapper {
  width: 100%;
  height: 368px;
}

.chart {
  width: 100%;
  height: 368px;
}
</style>